import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { MatSnackBar, MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../../../services/auth.service';
import { StateService } from '../../../../services/state.service';
import { MachinesService } from '../../../../services/machines.service';
import { PartsService } from '../../../../services/parts.service';
import { PricesService } from '../../../../services/prices.service';
import { ClaimsService } from '../../../../services/claims.service';
import { CustomersService, ICustomerInfo } from 'src/app/services/customers.service';
import { CurrenciesService, ICurrencyInfo } from 'src/app/services/currencies.service';
import { LocaleService } from 'src/app/services/locale.service';

import { ConfirmDialogComponent } from 'src/app/shared/dialogs/components/confirm-dialog/confirm-dialog.component';
import { InvoiceDialogComponent } from 'src/app/shared/dialogs/components/invoice-dialog/invoice-dialog.component';
import { DiscardConfirmDialogComponent } from 'src/app/shared/dialogs/components/discard-dialog/discard-confirm-dialog.component';

import { CompanyCodes } from 'src/app/models/company';
import { CurrencyCodes } from 'src/app/models/currency';
import { Claim, SparePart, OtherCost, RepairCost } from 'src/app/models/claim';
import { IPart } from 'src/app/models/IPart';
import { IPartPrice } from 'src/app/models/IPartPrice';
import { ILabourPrice } from 'src/app/models/ILabourPrice';
import { FaultyDescriptionCategories, FaultyDescriptionTypes, FaultyDescriptionSections, FaultyDescriptionReasons,
    FaultyDescriptionAllowedMappings,
    updateFaultyDescriptionObject } from 'src/app/models/faulty-description';
import { ClaimActions } from './claim-actions';

const SUPPORTED_ATTACHMENTS = [
    { extension: 'png',  type: 'image/png' },
    { extension: 'jpg',  type: 'image/jpeg' },
    { extension: 'jpeg', type: 'image/jpeg' },
    { extension: 'gif',  type: 'image/gif' },
    { extension: 'json', type: 'application/json' },
    { extension: 'pdf',  type: 'application/pdf' },
    { extension: 'doc',  type: 'application/msword' },
    { extension: 'docx', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    { extension: 'xls',  type: 'application/vnd.ms-excel' },
    { extension: 'xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
];

const WUXI_MANUFACTURED_PRODUCTS = [
    { productId: '6150500FS', productName: 'FASTSTEEL 5000' },
    { productId: '6152100FS', productName: 'FASTSTEEL MXF 65' },
    { productId: '6068100FS', productName: 'FASTSTEEL FASTCOOL 10' }
];

@Component({
    selector: 'app-claim-page',
    templateUrl: './claim-page.component.html',
    styleUrls: ['./claim-page.component.scss']
})
export class ClaimPageComponent implements OnInit, OnDestroy {

    /**
     * State of page
     */
    state: any;

    /**
     * Constants
     */
    constants: any;

    /**
     * Claim company, url parameter claims/:company/:partnerId/:id
     */
    company: string;

    /**
     * Claim partnerId, url parameter claims/:company/:partnerId/:id
     */
    partnerId: string;

    /**
     * Claim id, url parameter claims/:company/:partnerId/:id
     */
    id: string;

    /**
     * Warranty claim object
     */
    claim: Claim;

    /**
     * Current / Updated products serial info loaded when claim is opened
     * Compare with the claim product and show *Changed or *Expired hints in product row.
     */
    productsOnLoad: {};

    /**
     * Available company codes suchs as '100', '109', '130'...
     */
    companyCodes: Array<string>;

    /**
     * Available currecy codes suchs as 'EUR', 'USD'...
     */
    currencyCodes: Array<string>;

    /**
     * Collection of claims that contains faulty product serial number
     */
    previousClaims: Array<any>;

    /**
     * Show action buttons toggler. (Actions are hidden on small mobile view)
     */
    showActions = false;

    /**
     * Claim allowed actions
     * Can user save, edit, delete the claim or add safety test
     * Can user add/remove approvals?
     */
    actions: ClaimActions;

    /**
     * Permission to edit the claim fields, check from auth service 'claims.write' action and user role
     * Disables fields and buttons in template
     */
    canEdit = false;

    /**
     * Permission to save the claim, check from auth service 'claims.write' action and claim current state
     * Disables approval and save buttons on toolbar
     */
    canSave = false;

    /**
     * Permission to add safety test (after claim is already sent to approval, but before its invoiced)
     */
    canAddSafety = false;

    /**
     * Permission to delete the claim, check from auth service 'claims.write' action
     * And current status
     * Delers and subsidiaries can only delete drafts
     */
    canDelete = false;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: any;

    /**
     * Edit approvals, (admins only)
     * If checked show all possible approval steps and show add and remove buttons
     */
    editApprovalsEnabled = false;

    /**
     * Comment field
     */
    comment: string;

    /**
     * Internal comment field
     */
    internalComment: string;

    /**
     * Faulty description
     * See models/faulty-description
     */
    // faultyDescSteps: Array<any>;
    FaultyDescriptionAllowedMappings: object;
    FaultyDescriptionCategories: Array<any>;
    FaultyDescriptionTypes: Array<any>;
    FaultyDescriptionSections: Array<any>;
    FaultyDescriptionReasons: Array<any>;

    /**
     * Authenticated user scope
     * partner / company / global
     */
    authUserScope = '';

    /**
     * Authenticated user role
     * koy_user / sub_user / cus_dealer / etc.
     */
    authUserRole = '';

    isDealerClaim: boolean;
    isDistributorClaim: boolean;

    // Admin tools
    showAdminTools = false;
    showJSON = false;
    validateEnabled = true;

    // transferTotalWithDiscount = 0;
    // transferTotalWithoutDiscount = 0;

    /**
     * Temporary spare parts to store previous values of spare parts.
     * When doing changes to parts, we have to update claim.data.repairs infos
     */
    tempProducts;

    /**
     * Temporary spare parts to store previous values of spare parts.
     * When doing changes to parts, we have to update claim.data.repairs infos
     */
    tempSpareParts;

    productLoading: boolean;
    invalidSerialNumber: boolean;
    invalidSecureId: boolean;
    notShippedSerial: boolean;
    stolenSerial: boolean;
    testSerieSerial: string;

    sparePartsLoading: boolean;

    /**
     * Show prices, some users like dealers employee is not allowed to see prices.
     */
    showPrices: boolean;

    _labourPrice: ILabourPrice;
    _partCustomerPrices: Array<IPartPrice>;
    _partVendorPrices: Array<IPartPrice>;

    /**
     * Is claim valid? Store validate() result
     */
    valid: boolean;

    navigationSubscription: any;

    /**
     * Attachment uploading, stores selected file info
     */
    attachment?: {
        file?: any;
        fileExtension?: string;
        preview?: boolean;
        content?: any;
    };

    translations: {
        changed: string,
        expired: string,
        notRegistered: string
    };

    constructor(
        private router: Router,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        public stateService: StateService,
        private machinesService: MachinesService,
        private partsService: PartsService,
        private claimsService: ClaimsService,
        private customersService: CustomersService,
        private currenciesService: CurrenciesService,
        private pricesService: PricesService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private localeService: LocaleService,
        private translateService: TranslateService
    ) {

        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event and claim id is changed re-initalise the component
            if (e instanceof NavigationEnd) {
                if (this.claim && this.claim.id && this.claim.id !== this.activatedRoute.snapshot.params['id']) {
                    // Force reload page
                    this.reloadPage();
                }
            }
        });

        this.state = {
            busy: true,
            originalData: null
        };

        this.constants = {
            ADDEDREPAIR: 'ADDREPAIR',
            ADDEDSAFETY: 'ADDSAFETY',
            CALIBRATION: 'CALIBRATION'
        };

        this.companyCodes = CompanyCodes;
        this.currencyCodes = CurrencyCodes;
        this.FaultyDescriptionCategories = FaultyDescriptionCategories;
        this.FaultyDescriptionTypes = FaultyDescriptionTypes;
        this.FaultyDescriptionSections = FaultyDescriptionSections;
        this.FaultyDescriptionReasons = FaultyDescriptionReasons;
        this.FaultyDescriptionAllowedMappings = FaultyDescriptionAllowedMappings;

        this.productsOnLoad = [];
        this.tempProducts = [];
        this.tempSpareParts = [];
        this.productLoading = false;
        this.invalidSerialNumber = false;
        this.invalidSecureId = false;
        this.sparePartsLoading = false;
        this.showPrices = this.authService.userProfile.showPrices;

        this.comment = '';
        this.internalComment = '';
    }

    ngOnInit() {
        setTimeout(async () => {
            this.stateService.state.hasBack = true;

            this.authUserScope = this.authService.userProfile.permissions.scope;
            this.authUserRole = this.authService.userProfile.role;

            this.company = this.activatedRoute.snapshot.params['company'];
            this.partnerId = this.activatedRoute.snapshot.params['partnerId'];
            this.id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = this.id;

            this.loadWarningTranslations();

            if (this.id === 'new') {
                this.claim = this.getNewClaimTemplate();
                this.updateFaultyDescription();
                this.actions = new ClaimActions(this.authService, this.claim);
                // this.setCanEditAndSave();
                // this.setCanDelete();
                this.calculateTransferCosts();
                this.getCustomerInfo();
                this.storeOriginalData();
            } else {
                // Load existing claim
                this.getClaimInfo();
            }

            this.state.busy = false;
        });
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }

    /**
     * Detect changes, if current claim data contains unsaved changes open discard confirmation dialog
     */
    canDeactivate(): Promise<boolean> | boolean {
        return new Promise( (resolve, reject) => {
            // If current claim data is remained unchanged return true and allow routing to another url
            if (this.claim && this.state.originalData
                && _.isEqual(this.claim.id, this.state.originalData.id)
                && _.isEqual(this.claim.company, this.state.originalData.company)
                && _.isEqual(this.claim.partnerId, this.state.originalData.partnerId)
                && _.isEqual(this.claim.partnerName, this.state.originalData.partnerName)
                && _.isEqual(this.claim.data, this.state.originalData.data)
            ) {
                resolve(true);
                return;
            }
            // Otherwise ask the user confirmation with the dialog. Return its promise which resolves to true or false when the user decides
            const dialogRef = this.dialog.open(DiscardConfirmDialogComponent);
            dialogRef.afterClosed().subscribe(results => {
                resolve(results);
            });
        });
    }

    // -----------------
    // Claim functions
    // -----------------
    /**
     * Save the claim
     */
    async save(approvalAction = null, approvalType = null) {
        this.state.busy = true;
        // Validate and show error
        this.validate();
        if (this.valid === false) {
            let validateErrorMessage = 'All required fields are not filled.';
            if (!this.claim.data.products || !this.claim.data.products[0] || !this.claim.data.products[0].productId || this.claim.data.products[0].productId === '') {
                validateErrorMessage = 'All required fields are not filled.\nPlease check PRODUCT section.';
            } else if (!this.claim.data.faultyDescription
                || !this.claim.data.faultyDescription.category
                || !this.claim.data.faultyDescription.type
                || !this.claim.data.faultyDescription.section
                || !this.claim.data.faultyDescription.reason
                || !this.claim.data.description) {
                validateErrorMessage = 'All required fields are not filled.\nPlease check REPAIR INFO section.';
            } else if (this.claim.data.safetyTest && (this.claim.data.attachments.length === 0 || !this.attachment || !this.attachment.file)) {
                validateErrorMessage = 'Safety test requires attachment.';
            }
            this.showError({ message: validateErrorMessage });
            this.state.busy = false;
            return;
        }

        // Check unsaved comments and attachments
        // (Writed comment, but not click the "add comment" button)
        if (this.comment !== '') {
            console.log('save() comment');
            this.addComment();
        }
        if (this.internalComment !== '') {
            console.log('save() internal comment');
            this.addInternalComment();
        }
        if (this.attachment && this.attachment.file && this.attachment.content && this.claim.id) {
            console.log('save() attachment');
            await this.saveAttachment();
        }

        // Validation OK, save claim
        this.claimsService.saveClaim(this.claim.company, this.claim.partnerId, this.claim.id, this.claim, approvalAction, approvalType).subscribe((result: any) => {
            if (result) {
                this.claim.id = result;
            }
            this.notify('claims.notifications.claim_successfully_saved', this.claim.id);
            this.storeOriginalData();
            this.router.navigate([`/claims/${this.claim.company}/${this.claim.partnerId}/${this.claim.id}`]);
            this.ngOnInit();
        }, (error) => {
            this.showError(error);
        });
    }

    /**
     * Delete claim
     * openDeleteDialog() confirms the delete first
     */
    delete() {
        this.claimsService.deleteClaim(this.claim.company, this.claim.partnerId, this.claim.id).subscribe((result: any) => {
            this.notify('claims.notifications.claim_successfully_deleted', this.claim.id);
            this.router.navigate([`/claims`]);
        }, (error) => {
            this.showError(error);
        });
    }

    cancel() {
        this.router.navigate(['/claims']);
    }

    /**
     * Validate claim, all required fields must be filled
     */
    validate() {
        if (!this.validateEnabled) {
            this.valid = true;
            return;
        }

        let valid = true;
        if (this.claim.data.claimType === 'SERIAL' && (!this.claim.data.products[0].serialNumber || this.claim.data.products[0].serialNumber === '')) { valid = false; }
        if (!this.claim.data.products[0].productId || this.claim.data.products[0].productId === '') { valid = false; }
        if (!this.claim.data.faultyDescription) { valid = false; }
        if (!this.claim.data.faultyDescription.category) { valid = false; }
        if (!this.claim.data.faultyDescription.type) { valid = false; }
        if (!this.claim.data.faultyDescription.section) { valid = false; }
        if (!this.claim.data.faultyDescription.reason) { valid = false; }
        if (!this.claim.data.description) { valid = false; }
        if (this.claim.data.safetyTest && this.claim.data.attachments.length === 0) { valid = false; }
        // Price validation is done "validatePrices()" method and used in "openApprovalsConfirmDialog()"
        this.valid = valid;
    }

    /**
     * Validate claim prices, used when subsidiary or kemppi oy are accepting the claim
     * Used in openApprovalsConfirmDialog()
     */
    validatePrices() {
        if (!this.validateEnabled) {
            return true;
        }
        // Skip price validation for Kemppi internal
        // And for CH*/100 partners (KSR)
        if (this.claim.partnerId === this.claim.company
            || (this.claim.partnerId.startsWith('CH') && this.claim.company === '100')
        ) {
            return true;
        }

        let valid = true;
        // Check prices, if 0 raise the validation not valid.
        for (const part of this.claim.data.parts) {
            if (part.priceTotal === 0) {
                if (part.partNumber === this.constants.CALIBRATION) {
                    // Skip CALIBRATION parts (always 0)
                } else {
                    valid = false;
                }
            }
        }
        // for (const repair of this.claim.data.repairs) {
        //     if (repair.repairCost === 0) { valid = false; }
        // }
        return valid;
    }

    /**
     * Update faulty description code, called after any change of faulty description steps
     * (see template)
     */
    updateFaultyDescription() {
        // this.claim.data.faultyDescription = _.map(this.faultyDescSteps).join('');

        if (typeof this.claim.data.faultyDescription === 'object') {
            // Clear type if it is not included in allowed types
            if (!_.includes(FaultyDescriptionAllowedMappings.types[this.claim.data.faultyDescription.category], this.claim.data.faultyDescription.type)) {
                this.claim.data.faultyDescription.type = null;
            }
            // Clear section if it is not included in allowed types
            if (!_.includes(FaultyDescriptionAllowedMappings.sections[this.claim.data.faultyDescription.type], this.claim.data.faultyDescription.section)) {
                this.claim.data.faultyDescription.section = null;
            }
            // Clear reason if it is not included in allowed types
            if (!_.includes(FaultyDescriptionAllowedMappings.reasons[this.claim.data.faultyDescription.type], this.claim.data.faultyDescription.reason)) {
                this.claim.data.faultyDescription.reason = null;
            }

            // Auto select N/A's for project
            if (this.claim.data.faultyDescription.category === 'project') {
                this.claim.data.faultyDescription.type = 'not-applicable';
                this.claim.data.faultyDescription.section = 'not-applicable';
                this.claim.data.faultyDescription.reason = 'not-applicable';
            }
        }

        // Update faulty description object
        this.claim.data.faultyDescription = updateFaultyDescriptionObject(this.claim.data.faultyDescription);

        // Filter allowed dropdown selections
        // Types
        this.FaultyDescriptionTypes = _.filter(FaultyDescriptionTypes, (fdtype) => {
            return _.includes(FaultyDescriptionAllowedMappings.types[this.claim.data.faultyDescription.category] || [], fdtype.key);
        });
        // Sections
        this.FaultyDescriptionSections = _.filter(FaultyDescriptionSections, (fdsection) => {
            return _.includes(FaultyDescriptionAllowedMappings.sections[this.claim.data.faultyDescription.type] || [], fdsection.key);
        });
        // Reasons
        this.FaultyDescriptionReasons = _.filter(FaultyDescriptionReasons, (fdreason) => {
            return _.includes(FaultyDescriptionAllowedMappings.reasons[this.claim.data.faultyDescription.type] || [], fdreason.key);
        });
    }

    /**
     * Get claim from backend by company number, partner id and claim id
     */
    getClaimInfo() {
        this.claimsService.getClaim(this.company, this.partnerId, this.id).subscribe((claim: Claim) => {
            this.claim = claim;
            console.log('claim', this.claim);
            if (this.claim) {
                this.convertData();
                this.updateFaultyDescription();
                // this.checkClaimOwnerType();
                this.loadProductInfos();
                this.actions = new ClaimActions(this.authService, this.claim);
                console.log(this.actions.approvals);
                // this.setCanEditAndSave();
                // this.setCanDelete();
                this.storeOriginalData();
                this.tempProducts = _.cloneDeep(this.claim.data.products);
                this.tempSpareParts = _.cloneDeep(this.claim.data.parts);
            } else {
                this.showError({ message: 'Unable to load claim ' + this.id });
                throw new Error('Error fetching claim');
            }
        }, (error) => {
            this.showError(error);
        });
    }

    /**
     * Convert data to new version
     * Legacy support
     */
    convertData() {
        if (this.claim.schema.version === '0.5') {
            console.log('Converting claim from version 0.5 -> 1.0');
            this.claim.data.claimType = this.claim.data.claimType ? this.claim.data.claimType : 'SERIAL';
            this.claim.data.currency.effectiveDate = this.claim.data.currency.effectiveDate !== '' ? this.claim.data.currency.effectiveDate : null;
            // Fix for old versions, replace workCosts with repairCosts (REMOVE)
            if (this.claim.data.costs && this.claim.data.costs.workCosts) {
                this.claim.data.costs.repairCosts = this.claim.data.costs.workCosts;
                this.claim.data.costs.repairCostsEUR = this.claim.data.costs.workCostsEUR;
                delete this.claim.data.costs.workCosts;
                delete this.claim.data.costs.workCostsEUR;
            }
            this.claim.data.parts.forEach(part => {
                console.log('convert', part);
                part.partName = part.partName ? part.partName : _.get(part, 'sparePartName');
                delete part.sparePartName;
            });
            this.claim.schema.version = '1.0';
        }
        // Add shipping to data
        if (!this.claim.data.shipping) {
            this.claim.data.shipping = { comment: '' };
        }
        // Add logistics state
        if (!this.claim.state.logisticsState) {
            this.claim.state.logisticsState = '';
        }
    }

    /**
     * Sets canEdit and canSave boolean flags.
     * Checks claim current states, user scopes and roles to allow edit or save.
     */
    setCanEditAndSave() {
        // CLAIMS WRITE permission required
        if (this.authService.isAllowedTo('claims.write', this.company, this.partnerId)
            || this.authService.isWuxiAllowedTo('claims.write', this.claim)
        ) {
            if (this.claim.state.closed) {
                // claim = CLOSED
                this.canEdit = false;
                this.canSave = false;
            } else if (this.id === 'new') {
                // claim = NEW
                this.canEdit = true;
                this.canSave = true;
            } else if (this.authUserScope === 'partner' && (
                this.claim.state.currentState === 'DRAFT'
                || this.claim.state.currentState === 'RETURNED'
            )) {
                // roles: cus_dealer, cus_direct, cus_distributor, cus_workshop
                this.canEdit = true;
                this.canSave = true;
            } else if (this.authUserScope === 'company' && (
                this.claim.state.currentState === 'DRAFT'
                || this.claim.state.currentState === 'OPEN'
                || this.claim.state.currentState === 'IN PROGRESS'
                || (this.claim.state.currentState === 'ACCEPTED' && this.claim.state.internalState === 'RETURNED')
                || (this.claim.state.currentState === 'ACCEPTED' && this.authService.isWuxiAllowedTo('claims.write', this.claim))
            )) {
                // roles: sub_user
                this.canEdit = true;
                this.canSave = true;
            } else if (this.authUserScope === 'global') {
                // roles: sys_admin, koy_user, koy_logistics
                if (this.authUserRole === 'koy_logistics') {
                    this.canEdit = false;
                } else {
                    this.canEdit = true;
                }
                this.canSave = true;
                console.log('canEditAndSave', this.canEdit, this.canSave);
            } else {
                this.canEdit = false;
                this.canSave = false;
            }

            // Extra permission to add safety test during the approval process
            if (this.id && this.id !== 'new' && !this.claim.data.safetyTest && (this.claim.state.invoiceState === '')) {
                this.canAddSafety = true;
            } else {
                this.canAddSafety = false;
            }
        } else {
            // readonly users edit and save always false
            this.canEdit = false;
            this.canSave = false;
        }
    }

    /**
     * Sets canDelete boolean flag. Checks is user allowed to delete the claim.
     */
    setCanDelete() {
        this.canDelete = this.authService.isAllowedTo('claims.write', this.company, this.partnerId)
            && (_.get(this.claim, 'state.currentState') === 'DRAFT');
    }

    /**
     * Reload page (by force)
     * Page not reloading cause we are navigating to same component. Force reload this page.
     * Used after saving when claim id is generated by backend.
     */
    reloadPage() {
        this.claim = null;
        this.ngOnInit();
        const contentContainer = document.querySelector('.main') || window;
        contentContainer.scrollTo(0, 0);
    }

    /**
     * Store original claim data.
     * Used for canDeactivate() to check any changes. (Shows discard changes dialog)
     */
    storeOriginalData() {
        this.state.originalData = _.cloneDeep(this.claim);
    }


    // -----------------
    // Customer / Partner info
    // -----------------

    checkClaimOwnerType() {
        if (this.claim.company === '100' && !this.claim.partnerId.startsWith('FI') && this.companyCodes.indexOf(this.claim.partnerId) === -1) {
            this.isDistributorClaim = true;
        } else {
            this.isDistributorClaim = false;
        }

        if (this.companyCodes.indexOf(this.claim.partnerId) === -1) {
            this.isDealerClaim = true;
        } else {
            this.isDealerClaim = false;
        }
    }

    /**
     * Is current claim owner domestic dealer or distributor
     */
    isDistributorOrDomesticDealer() {
        if (this.claim.company === '100' && this.companyCodes.indexOf(this.claim.partnerId) === -1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Is current claim owner subsidiary
     */
    isSubsidiary() {
        if (this.claim.company === this.claim.partnerId && this.companyCodes.indexOf(this.claim.company) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Get customer info from machinery backend by company number and partner id
     */
    getCustomerInfo() {
        let company = this.claim.company;
        // Exception for subsidiaries, get customer info with company 100 (Different currency)
        if (this.isSubsidiary()) {
            company = '100';
        }

        this.customersService.getCustomerInfo(company, this.claim.partnerId).subscribe(async (customerInfo: ICustomerInfo) => {
            if (customerInfo) {
                this.claim.partnerName = customerInfo.name;
                this.claim.data.partner.name = customerInfo.name;
                this.claim.data.partner.groupCode = customerInfo.groupCode;
                this.claim.data.partner.groupDesc = customerInfo.groupDesc;
                this.claim.data.language = customerInfo.langNameId;
                this.claim.data.currency.code = customerInfo.currencyCode;
                await this.getLabourPrice();
                this.getCurrencyRate();
                // this.checkClaimOwnerType();
                this.actions.update();
            } else {
                this.claim.partnerName = '';
                this.claim.data.partner.name = '';
                this.claim.data.partner.groupCode = '';
                this.claim.data.partner.groupDesc = '';
                this.claim.data.language = '';
                this.claim.data.currency.code = '';
                this.claim.data.currency.currencyRate = 1;
                this.claim.data.currency.effectiveDate = null;
                this.claim.data.repair = this.claim.data.repair ? this.claim.data.repair : {};
                this.claim.data.repair.labourPrice = 0;
            }
        }, (error) => {
            console.log('ERROR fetching customerInfo', error);
        });
    }


    // -----------------
    // Product(s)
    // -----------------

    /**
     * Load current product (machine) infos.
     * productsOnLoad holds the current versions of infos.
     * Info might be changed after the claim is saved. We don't automatically update product info, but changes are shown to user.
     */
    loadProductInfos() {
        if (!this.claim.data.products || this.claim.data.products.length === 0) {
            this.claim.data.products = [{ serialNumber: null }];
        }
        // Load current product infos by serial number. Compared with saved data on the claim.
        let index = 0;
        for (const product of this.claim.data.products) {
            if (product.serialNumber) {
                const onLoadProduct = {};
                this.getSerialInfo(onLoadProduct, product.serialNumber, product.secureId, index, true);
                this.productsOnLoad[product.serialNumber] = onLoadProduct;
            }
            index += 1;
        }
        // this.calculateTransferCosts(); // Triggers price calculation without "Update" on claim load.
        // this.getPreviousClaims();
    }

    /**
     * Get serial machine info from machinery backend
     */
    getSerialInfo(product, serialNumber, secureId, index, initial = false) {
        // let skipSecureIdCheck = initial ? true : false;
        let skipSecureIdCheck = true; // Always skip secureId check - 11.7.2019 kauppjus
        const skipUpdatePartInfo = initial ? true : false;

        this.invalidSerialNumber = false;
        this.invalidSecureId = false;
        this.notShippedSerial = false;
        this.stolenSerial = false;
        this.testSerieSerial = null;
        if (!serialNumber || serialNumber === '') {
            return;
        }
        // Transform to upper case and remove spaces
        serialNumber = serialNumber.toUpperCase().replace(/ /g, '');
        product.serialNumber = serialNumber;

        this.productLoading = true;
        this.machinesService.getMachineInfo(serialNumber).subscribe((machine: any) => {
            if (!machine) {
                this.invalidSerialNumber = true;
            }
            if (machine && machine.manufactureDate < '2018-01-01T00:00:00.000Z') {
                skipSecureIdCheck = true; // Skip secureId check for older machines
            }
            if (machine && !skipSecureIdCheck && secureId && machine.secureId !== secureId) {
                this.invalidSecureId = true;
            }
            if (machine && !machine.deliveryDate && (skipSecureIdCheck || machine.secureId === secureId)) {
                this.notShippedSerial = true;
            }
            if (machine && machine.stolen && (skipSecureIdCheck || machine.secureId === secureId)) {
                this.stolenSerial = true;
            }
            if (machine && machine.testSerie && (skipSecureIdCheck || machine.secureId === secureId)) {
                this.testSerieSerial = machine.testSerie;
            }

            // Machine must have delivery date
            if (machine && machine.deliveryDate && (skipSecureIdCheck || machine.secureId === secureId)) {
                product.productId = machine.productId;
                product.secureId = machine.secureId;
                product.productName = machine.productInfo ? machine.productInfo.partDescription : null;
                product.productGroup = machine.productInfo ? machine.productInfo.prodCode : null;
                product.partDescriptions = machine.productInfo ? machine.productInfo.partDescriptions : null;
                product.salesDate = machine.salesDate || machine.partnerRegistrationDate; // || machine.deliveryDate; // Check this. dealer registration is set to salesDate.
                product.partnerRegistrationDate = machine.partnerRegistrationDate;
                product.endCustomerRegistrationDate = machine.endCustomerRegistrationDate;
                product.manufactureCompany = machine.manufactureCompany;
                product.manufactureDate = machine.manufactureDate;
                product.warrantyStartDate = machine.warranyStartDate;
                product.warrantyExpireDate = machine.warrantyExpireDate;
                // Update end customer name if empty
                if (this.claim.data.customer.companyName === '' && (machine.registeredOrganizationName || machine.registeredEndCustomerName)) {
                    this.claim.data.customer.companyName = machine.registeredOrganizationName || machine.registeredEndCustomerName;
                }
                // Fetch previous claims
                this.getPreviousClaims();
                // Add basic repair time
                if (!skipUpdatePartInfo) {
                    this.updatePartInfo({ partNumber: product.productId, description: 'Basic repair time' }, product.productId, index, true);
                }
            } else {
                // Machine not found, clear all product info
                product.productId = null;
                product.productName = null;
                product.partDescriptions = null;
                product.salesDate = null;
                product.partnerRegistrationDate = null;
                product.endCustomerRegistrationDate = null;
                product.manufactureDate = null;
                product.warrantyStartDate = null;
                product.warrantyExpireDate = null;
                this.previousClaims = null;
                this.productLoading = false;
            }
            // Update claim approval process
            this.updateClaimApprovalProcess();
        }, (error) => {
            this.showError(error);
            this.productLoading = false;
        });
    }

    /**
     * Get product / part info from machinery backend
     */
    getProductInfo(product, productId, index) {
        // Transform to upper case and remove spaces
        productId = productId.toUpperCase().replace(/ /g, '');
        product.productId = product.productId.toUpperCase().replace(/ /g, '');
        this.productLoading = true;
        this.partsService.getPartInfo(productId).subscribe((partInfo: IPart) => {
            product.serialNumber = null;
            product.salesDate = null;
            product.warrantyExpireDate = null;
            if (partInfo) {
                product.productName = partInfo.partDescription;
                product.partDescriptions = partInfo.partDescriptions;
                // Add spare part
                if (this.claim.data.claimType === 'SPARE') {
                    this.addSparePart(product.productId);
                }
            } else {
                product.productName = null;
            }
            this.productLoading = false;
        }, (error) => {
            console.log('ERROR fetching productInfo', productId);
            this.productLoading = false;
        });
    }
    /**
     * Add new product row
     */
    addProductRow() {
        this.claim.data.products.push({});
        this.previousClaims = null;
    }
    /**
     * Delete product row
     */
    deleteProductRow(index) {
        this.deleteRepairInfoByPart(this.claim.data.products[index].productId);
        this.claim.data.products.splice(index, 1);

        if (this.claim.data.products.length === 0) {
            this.addProductRow();
        }
    }

    /**
     * Get previous claims with same product serial number from backend
     */
    getPreviousClaims() {
        // faultyUnit = legacy support for old eWarranty claims
        const serialNumber = _.get(_.find(this.claim.data.products, { faultyUnit: true }), 'serialNumber') || _.get(this.claim.data.products, '[0].serialNumber');
        if (serialNumber) {
            this.claimsService.getClaimsBySerialNumber(serialNumber).subscribe((previousClaims: any) => {
                if (previousClaims) {
                    // Filter this claim out of results
                    this.previousClaims = _.filter(previousClaims, (c) => {
                        return c.id !== this.id;
                    });
                } else {
                    this.previousClaims = null;
                }
                this.productLoading = false;
            }, (error) => {
                this.previousClaims = null;
                this.showError(error);
                this.productLoading = false;
            });
        }
    }


    // -----------------
    // Spare parts / repairs
    // -----------------

    /**
     * Add new blank spare part
     */
    addSparePart(partNumber = '') {
        const newSparePart: SparePart = {
            partNumber: partNumber,
            partName: '',
            partDescriptions: null,
            quantity: 1,
            faultCode: '',
            faultNumber: '',
            faultPartCode: '',
            price: 0,
            discount: 0,
            priceTotal: 0,
            priceTotalEUR: 0,
            transferPrice: 0,
            transferDiscount: 0,
            transferTotal: 0,
            transferTotalEUR: 0,
            transferTotalWithoutDiscount: 0
        };
        this.claim.data.parts.push(newSparePart);
        // Update spare part info
        if (partNumber !== '') {
            this.updatePartInfo(newSparePart, partNumber, this.claim.data.parts.length - 1); // index is last added
        }
    }
    /**
     * Delete spare part row by index
     */
    deleteSparePart(index) {
        this.deleteRepairInfoByPart(this.claim.data.parts[index].partNumber);
        this.claim.data.parts.splice(index, 1);
        this.calculateSparePartsCosts();
        this.calculateTransferCosts();
        this.calculateRepairCosts();
    }

    /**
     * Updates all part infos and prices
     */
    async updateAllSpareParts() {
        for (const sparePart of this.claim.data.parts) {
            const index = Object(this.claim.data.parts).indexOf(sparePart);
            await this.updatePartInfo(sparePart, sparePart.partNumber, index);
        }
    }

    /**
     * Update part info
     */
    async updatePartInfo(part, partNumber, i, product = false) {
        // Transform to upper case and remove spaces
        partNumber = partNumber.toUpperCase().replace(/ /g, '');
        part.partNumber = part.partNumber.toUpperCase().replace(/ /g, '');
        this.sparePartsLoading = true;

        // If part is changing, always delete its repair row first (using previous value)
        // Product row changing
        if (product && this.tempProducts[i] && this.tempProducts[i].productId) {
            this.deleteRepairInfoByPart(this.tempProducts[i].productId);
        }
        // Spare part row is changing
        if (!product && this.tempSpareParts[i] && this.tempSpareParts[i].partNumber) {
            this.deleteRepairInfoByPart(this.tempSpareParts[i].partNumber);
        }

        // Check how many times same part number is found
        // If more that one, ignore part updating
        const matchingProducts = _.filter(this.claim.data.products, { productId: partNumber });
        const matchingParts = _.filter(this.claim.data.parts, { partNumber: partNumber });
        if (matchingProducts.length === 1 || matchingParts.length === 1) {
            this.partsService.getPartInfo(partNumber).subscribe(async (partInfo: IPart) => {
                if (partInfo) {
                    part.inactive = partInfo.inactive;
                    part.partName = partInfo.partDescription;
                    part.partDescriptions = partInfo.partDescriptions;
                    part.faultPartCode = partInfo.identification;
                    part.salesUM = partInfo.salesUM;
                    part.repairTime = partInfo.repairTime;
                    if (this.claim.data.claimType === 'SERIAL') {
                        this.updateRepairInfo(part);
                    }
                } else {
                    part.inactive = null;
                    part.partName = null;
                    part.partDescriptions = null;
                    part.faultPartCode = null;
                    part.salesUM = null;
                    part.repairTime = null;
                    part.price = 0;
                    part.discount = 0;
                    part.transferPrice = 0;
                    part.transferDiscount = 0;
                }
                // Update prices
                await this.updatePartPrices();
                this.calculateSparePartRow(part);
                // Duplicate spareParts to temp to store old values
                this.tempProducts = _.cloneDeep(this.claim.data.products);
                this.tempSpareParts = _.cloneDeep(this.claim.data.parts);
                this.sparePartsLoading = false;
            }, (error) => {
                console.log('ERROR fetching productInfo', partNumber, i);
                this.sparePartsLoading = false;
            });
        } else {
            // Same part added to multiple rows, clear part
            part.inactive = null;
            part.partNumber = null;
            part.partName = null;
            part.partDescriptions = null;
            part.faultPartCode = null;
            part.salesUm = null;
            part.repairTime = null;
            part.price = 0;
            part.discount = 0;
            part.transferPrice = 0;
            part.transferDiscount = 0;
            this.calculateSparePartRow(part);
            // Duplicate spareParts to temp to store old values
            this.tempProducts = _.cloneDeep(this.claim.data.products);
            this.tempSpareParts = _.cloneDeep(this.claim.data.parts);
            this.sparePartsLoading = false;
        }
    }

    /**
     * Update repair info
     * Automatically append claim.data.repairs with spare parts information
     */
    updateRepairInfo(sparePart) {
        if (!this.claim.data.repairs) {
            this.claim.data.repairs = [];
        }
        const repair = {
            partNumber: sparePart.partNumber,
            partName: sparePart.partName,
            partDescriptions: sparePart.partDescriptions || null,
            description: sparePart.description || '',
            minutes: (sparePart.quantity || 1) * (sparePart.repairTime || 0),
            pricePerHour: this.claim.data.repair && this.claim.data.repair.labourPrice ? this.claim.data.repair.labourPrice : 0,
            repairCost: null // Calculate work cost ((minutes/60) * pricePerHour)
        };
        repair.repairCost = this.rounding((repair.minutes > 0 && repair.pricePerHour > 0) ? (repair.minutes / 60 * repair.pricePerHour) : 0);
        // Find item index by part number
        const index = _.findIndex(this.claim.data.repairs, { partNumber: sparePart.partNumber });
        if (index !== -1) {
            // Replace item at index
            this.claim.data.repairs.splice(index, 1, repair);
        } else {
            // Add new item
            this.claim.data.repairs.push(repair);
        }
        this.updateRepairHours();
        // Duplicate spareParts to temp to store old values
        this.tempSpareParts = _.cloneDeep(this.claim.data.parts);
        // Calculate total prices for current row
        this.calculateSparePartRow(sparePart);
        this.calculateRepairCosts();
    }
    /**
     * Update repair costs
     */
    updateRepairCosts() {
        this.claim.data.repairs.forEach(repair => {
            repair.pricePerHour = this.claim.data.repair && this.claim.data.repair.labourPrice ? this.claim.data.repair.labourPrice : 0;
            repair.repairCost = this.rounding((repair.minutes > 0 && repair.pricePerHour > 0) ? (repair.minutes / 60 * repair.pricePerHour) : 0);
        });
        this.updateRepairHours();
    }
    updateRepairHours() {
        this.claim.data.repair = this.claim.data.repair ? this.claim.data.repair : {};
        this.claim.data.repair.minutes = _.sumBy(this.claim.data.repairs, 'minutes');
        this.claim.data.repair.hours = this.rounding(_.sumBy(this.claim.data.repairs, 'minutes') / 60);
        this.claim.data.repair.time = this.timeConvertMinutesToHoursAndMinutes(this.claim.data.repair.minutes);
    }
    /**
     * Delete repair info row by part number
     */
    deleteRepairInfoByPart(partNumber) {
        // Find item index by part number
        const index = _.findIndex(this.claim.data.repairs, { partNumber: partNumber });
        if (index !== -1) {
            this.claim.data.repairs.splice(index, 1);
        }
        this.updateRepairHours();
    }
    /**
     * Delete repair info row by index
     */
    deleteRepair(index) {
        this.claim.data.repairs.splice(index, 1);
        this.updateRepairHours();
        this.calculateRepairCosts();
    }

    /**
     * Add additional repair cost
     * Additional repair costs raises additional confirmation dialog in approval
     */
    addRepairCost() {
        // Check is there already additional-repair cost row. Allow only one additional-repair cost row.
        const index = _.findIndex(this.claim.data.repairs, { partNumber: this.constants.ADDEDREPAIR });
        if (index === -1) {
            const repair: RepairCost = {
                partNumber: this.constants.ADDEDREPAIR,
                partName: 'Additional repair cost',
                partDescriptions: null,
                description: '',
                minutes: 0,
                pricePerHour: this.claim.data.repair.labourPrice ? this.claim.data.repair.labourPrice : 0,
                repairCost: 0
            };
            this.claim.data.repairs.push(repair);
        }
    }

    /**
     * Add safety test
     */
    addSafetyTest() {
        this.claim.data.safetyTest = true;
        this.addSafetyTestCost();
        document.getElementById('add-attachment').click();
        // After user have selected the attachment file, automatically save the claim.
        // See selectedFile(event) method
    }

    /**
     * Add safety test cost
     */
    addSafetyTestCost() {
        if (this.claim.data.safetyTest) {
            // Check is there already additional-safety cost row. Allow only one additional-safety cost row.
            const index = _.findIndex(this.claim.data.repairs, { partNumber: this.constants.ADDEDSAFETY });
            if (index === -1) {
                const repair: RepairCost = {
                    partNumber: this.constants.ADDEDSAFETY,
                    partName: 'Additional safety test cost',
                    partDescriptions: null,
                    description: '',
                    minutes: 15,
                    pricePerHour: this.claim.data.repair.labourPrice ? this.claim.data.repair.labourPrice : 0,
                    repairCost: 0 // below
                };
                repair.repairCost = this.rounding((repair.minutes > 0 && repair.pricePerHour > 0) ? (repair.minutes / 60 * repair.pricePerHour) : 0);
                this.claim.data.repairs.push(repair);
                this.updateRepairHours();
                this.calculateRepairCosts();
            }
        } else {
            this.deleteRepairInfoByPart(this.constants.ADDEDSAFETY);
        }
    }

    /**
     * Time convert minutes to hours and minutes
     * Helper method to convert total minutes to hours and minutes
     */
    timeConvertMinutesToHoursAndMinutes(totalmins) {
        const hours = (totalmins / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return rhours + 'h ' + rminutes + 'm';
    }


    // -----------------
    // Part prices
    // -----------------

    /**
     * Fetch part prices
     * Collect all products and spare parts numbers and get customer and vendor prices for them
     * Calls warranty backend that is linked to internal rest api.
     * Rest API uses Epicor custom made KOY_GetCustomerPartPrices and KOY_GetVendorPartPrices methods.
     * Store results to private prices arrays
     */
    async fetchPartPrices() {
        const productPartNumbers = this.claim.data.products.map(product => {
            return product.productId;
        });
        const sparePartNumbers = this.claim.data.parts.map(part => {
            return part.partNumber;
        });
        // Merge part numbers and remove undefined/nulls
        const partNumbers = _.compact(_.union(productPartNumbers, sparePartNumbers));

        if (partNumbers.length > 0) {
            // Customer prices
            if (!this.isSubsidiary()) {
                const customerPartPricesPromise = this.pricesService.getCustomerPartPrices(this.claim.company, this.claim.partnerId, partNumbers, this.claim.data.currency.code).toPromise();
                await Promise.all([customerPartPricesPromise]).then(values => {
                    this._partCustomerPrices = values[0] as Array<IPartPrice>;
                }).catch(error => {
                    console.log('Error fetching customer prices', error);
                });
            }
            // Transfer (vendor) prices
            if (!this.isDistributorOrDomesticDealer()) {
                const approvalProcess = _.get(this.claim, 'state.approvalProcess');
                const vendorId = approvalProcess === 'WUXI' ? '280' : '100';
                const vendorPartPricesPromise = this.pricesService.getVendorPartPrices(this.claim.company, this.claim.partnerId, vendorId, partNumbers, this.claim.data.currency.code).toPromise();
                await Promise.all([vendorPartPricesPromise]).then(values => {
                    this._partVendorPrices = values[0] as Array<IPartPrice>;
                }).catch(error => {
                    console.log('Error fetching vendor prices', error);
                });
            }

            // Check internal currency
            await this.checkCurrencies();
        }
    }

    /**
     * Update part prices
     */
    async updatePartPrices() {
        this.sparePartsLoading = true;
        // Get prices from ERP
        await this.fetchPartPrices();
        // Update prices to parts
        for (const part of this.claim.data.parts) {
            if (!part.customPrice) {
                part.price = this.getPartCustomerPrice(part.partNumber, part.quantity);
                part.discount = this.getPartCustomerDiscount(part.partNumber, part.quantity);
                part.transferPrice = this.getPartVendorPrice(part.partNumber, part.quantity);
                part.transferDiscount = this.getPartVendorDiscount(part.partNumber, part.quantity);
                part.priceTotal = this.rounding(part.quantity * part.price * (1 - part.discount / 100));
                part.priceTotalEUR = this.rounding(part.priceTotal / this.claim.data.currency.currencyRate);
                part.transferTotal = this.rounding(part.quantity * part.transferPrice * (1 - part.transferDiscount / 100));
                if (this.claim.data.currency.internal) {
                    part.transferTotalEUR = this.rounding(part.transferTotal / this.claim.data.currency.internal.currencyRate);
                } else {
                    part.transferTotalEUR = this.rounding(part.transferTotal / this.claim.data.currency.currencyRate);
                }
            }
        }
        // Calcule total costs
        await this.calculateSparePartsCosts();
        await this.calculateTransferCosts();
        this.sparePartsLoading = false;
    }
    /**
     * Get part customer price from private price array
     * Filter quantity breaks first
     */
    getPartCustomerPrice(partNumber, quantity) {
        const filteredPrices = _.filter(this._partCustomerPrices, (price) => {
            return price.PartNum === partNumber && price.Quantity <= quantity;
        });
        const priceItem: IPartPrice = _.first(_.orderBy(filteredPrices, ['Quantity'], ['desc'])) as IPartPrice;
        if (priceItem && priceItem.Price) {
            return priceItem.Price;
        } else {
            return 0;
        }
    }
    /**
     * Get part customer discount from private price array
     * Filter quantity breaks first
     */
    getPartCustomerDiscount(partNumber, quantity) {
        const filteredPrices = _.filter(this._partCustomerPrices, (price) => {
            return price.PartNum === partNumber && price.Quantity <= quantity;
        });
        const priceItem: IPartPrice = _.first(_.orderBy(filteredPrices, ['Quantity'], ['desc'])) as IPartPrice;
        if (priceItem && priceItem.DiscountPercent) {
            return priceItem.DiscountPercent;
        } else {
            return 0;
        }
    }
    /**
     * Get part vendor price from private price array
     * Filter quantity breaks first
     */
    getPartVendorPrice(partNumber, quantity) {
        const filteredPrices = _.filter(this._partVendorPrices, (price) => {
            return price.PartNum === partNumber && price.Quantity <= quantity;
        });
        const priceItem: IPartPrice = _.first(_.orderBy(filteredPrices, ['Quantity'], ['desc'])) as IPartPrice;
        if (priceItem && priceItem.Price) {
            return priceItem.Price;
        } else {
            return 0;
        }
    }
    /**
     * Get part vendor discount from private price array
     * Filter quantity breaks first
     */
    getPartVendorDiscount(partNumber, quantity) {
        const filteredPrices = _.filter(this._partVendorPrices, (price) => {
            return price.PartNum === partNumber && price.Quantity <= quantity;
        });
        const priceItem: IPartPrice = _.first(_.orderBy(filteredPrices, ['Quantity'], ['desc'])) as IPartPrice;
        if (priceItem && priceItem.DiscountPercent) {
            return priceItem.DiscountPercent;
        } else {
            return 0;
        }
    }

    /**
     * Get labour price from warranty backend
     * Kemppi Oy and subsidiaries update these prices
     * See: settings/labour-costs page
     */
    async getLabourPrice() {
        this._labourPrice = null;
        const labourPricePromise = this.pricesService.getRepairLabourPrice(this.claim.company, this.claim.partnerId).toPromise();

        await Promise.all([labourPricePromise]).then(values => {
            this._labourPrice = values[0] as ILabourPrice;
            // Update labour price to the claim
            this.claim.data.repair = this.claim.data.repair ? this.claim.data.repair : {};
            this.claim.data.repair.labourPrice = this._labourPrice ? this._labourPrice.cost : 0;
            this.claim.data.repair.labourCurrency = this._labourPrice ? this._labourPrice.currency : null;
        }).catch(error => {
            console.log('Error fetching labour price', error);
        });
    }


    // -----------------
    // Other costs
    // -----------------

    /**
     * Add other cost
     * Other costs raises additional confirmation dialog in approval
     */
    addOtherCost() {
        const newOtherCost: OtherCost = {
            description: '',
            otherCost: 0
        };
        this.claim.data.others.push(newOtherCost);
    }
    /**
     * Delete other costs row by index
     */
    deleteOtherCost(index) {
        this.claim.data.others.splice(index, 1);
        this.calculateOtherCosts();
    }


    // -----------------
    // Currency & Costs
    // -----------------

    /**
     * Get currency rate by currency code and repair date from machinery backend
     * (Currencies are constantly changing so they have effective date that determines what row should be used)
     */
    getCurrencyRate() {
        if (this.claim.data.currency.code === 'EUR') {
            this.claim.data.currency.currencyRate = 1;
            this.claim.data.currency.effectiveDate = null;
            this.updateCurrencyRate();
        } else {
            this.currenciesService.getCurrencyInfo(this.claim.data.currency.code, this.claim.data.repairDate).subscribe((currencyInfo: ICurrencyInfo) => {
                if (currencyInfo) {
                    this.claim.data.currency.currencyRate = currencyInfo.currentRate;
                    this.claim.data.currency.effectiveDate = currencyInfo.effectiveDate;
                    this.updateCurrencyRate();
                }
            }, (error) => {
                console.log('ERROR fetching currencyInfo');
            });
        }
    }

    /**
     * When prices are fetched check that currency and currency.internal are not different or changed
     * This is mainly created for Indian where indian customer uses INR prices and transfer prices are in EUR
     * Other companies use same currencies for both EUR EUR or for example sweden SEK SEK
     * Ignore this if currencies are same
     */
    async checkCurrencies() {
        const currentCurrencyCode = _.get(this.claim, 'data.currency.code');
        const currentCurrencyInternalCode = _.get(this.claim, 'data.currency.internal.code');

        const vendorPricesCurrency = _.get(this._partVendorPrices, '[0].CurrencyCode');

        if (vendorPricesCurrency && currentCurrencyCode !== vendorPricesCurrency && currentCurrencyCode !== currentCurrencyInternalCode) {
            console.log('UPDATE internal currency');
            _.set(this.claim, 'data.currency.internal', {
                code: vendorPricesCurrency,
                currencyRate: 1,
                effectiveDate: null
            });
            // Is this ever triggered? Currency and internal currency are not matching and internal is not EUR
            if (vendorPricesCurrency !== 'EUR') {
                await this.currenciesService.getCurrencyInfo(this.claim.data.currency.internal.code, this.claim.data.repairDate).subscribe((currencyInfo: ICurrencyInfo) => {
                    if (currencyInfo) {
                        console.log('currencyInfo', currencyInfo);
                        this.claim.data.currency.internal.currencyRate = currencyInfo.currentRate;
                        this.claim.data.currency.internal.effectiveDate = currencyInfo.effectiveDate;
                    }
                }, (error) => {
                    console.log('ERROR fetching currencyInfo');
                });
            }
        }
    }

    /**
     * Currency is changed, update currency rate and recalculate all costs
     */
    updateCurrencyRate() {
        this.updatePartPrices();
        this.updateRepairCosts();
        this.calculateSparePartsCosts();
        this.calculateTransferCosts();
        this.calculateRepairCosts();
        this.calculateOtherCosts();
    }

    /**
     * Calculate single spare part total costs
     * If customPrice flag is set, admin is modifying the price manually, add ustomPrice flag to spare part.
     */
    calculateSparePartRow(sparePart, customPrice = false) {
        console.log('calculateSparePartRow', sparePart.customPrice, customPrice);
        if (customPrice) {
            sparePart.customPrice = true;
        }
        sparePart.priceTotal = this.rounding(sparePart.quantity * sparePart.price * (1 - sparePart.discount / 100));
        sparePart.priceTotalEUR = this.rounding(sparePart.priceTotal / this.claim.data.currency.currencyRate);
        sparePart.transferTotal = this.rounding(sparePart.quantity * sparePart.transferPrice * (1 - sparePart.transferDiscount / 100));
        if (this.claim.data.currency.internal) {
            sparePart.transferTotalEUR = this.rounding(sparePart.transferTotal / this.claim.data.currency.internal.currencyRate);
        } else {
            sparePart.transferTotalEUR = this.rounding(sparePart.transferTotal / this.claim.data.currency.currencyRate);
        }
        sparePart.transferTotalWithoutDiscount = this.rounding(sparePart.quantity * sparePart.transferPrice);
        console.log('sparePart.transferTotalWithoutDiscount', sparePart.transferTotalWithoutDiscount);
        this.calculateSparePartsCosts();
        this.calculateTransferCosts();
    }
    /**
     * Reset / remove custom price
     */
    async removeCustomPrice(sparePart, index) {
        if (sparePart.customPrice) {
            delete sparePart.customPrice;
            await this.updatePartInfo(sparePart, sparePart.partNumber, index);
        }
    }

    /**
     * Calculate claim total spare part costs
     */
    calculateSparePartsCosts() {
        this.claim.data.costs.partCosts = this.rounding(_.sumBy(this.claim.data.parts, 'priceTotal'));
        this.claim.data.costs.partCostsEUR = this.rounding(this.claim.data.costs.partCosts / this.claim.data.currency.currencyRate);
        this.calculateTotalCosts();
    }
    /**
     * Calculate total transfer costs
     */
    calculateTransferCosts() {
        // this.transferTotalWithDiscount = this.rounding(_.sumBy(this.claim.data.parts, 'transferTotal'));
        this.claim.data.costs.partTransferCosts = this.rounding(_.sumBy(this.claim.data.parts, 'transferTotal'));
        // this.transferTotalWithoutDiscount = this.rounding(_.sumBy(this.claim.data.parts, 'transferTotalWithoutDiscount'));
        this.claim.data.costs.partTransferCostsWithoutDiscount = this.rounding(_.sumBy(this.claim.data.parts, 'transferTotalWithoutDiscount'));
        this.calculateTotalCosts();
    }
    /**
     * Calculate total repair / work costs
     */
    calculateRepairCosts() {
        this.claim.data.costs.repairCosts = this.rounding(_.sumBy(this.claim.data.repairs, 'repairCost'));
        this.claim.data.costs.repairCostsEUR = this.rounding(this.claim.data.costs.repairCosts / this.claim.data.currency.currencyRate);
        this.calculateTotalCosts();
    }
    /**
     * Calculate total other costs
     */
    calculateOtherCosts() {
        this.claim.data.costs.otherCosts = this.rounding(_.sumBy(this.claim.data.others, 'otherCost'));
        this.claim.data.costs.otherCostsEUR = this.rounding(this.claim.data.costs.otherCosts / this.claim.data.currency.currencyRate);
        this.calculateTotalCosts();
    }
    /**
     * Calculate total costs
     */
    calculateTotalCosts() {
        // On subsidiary claims, do not calculate repair and other costs to total costs
        if (this.isSubsidiary()) {
            this.claim.data.costs.totalCosts = 0;
        } else {
            this.claim.data.costs.totalCosts = this.rounding(this.claim.data.costs.partCosts + this.claim.data.costs.repairCosts + this.claim.data.costs.otherCosts);
        }

        // On distributor and domestic dealer claims, do not calculater repair and other costs to transfer costs
        if (this.isDistributorOrDomesticDealer()) {
            this.claim.data.costs.totalTransferCosts = 0;
            this.claim.data.costs.totalTransferCostsEUR = 0;
        } else {
            if (this.claim.data.currency.internal) {
                this.claim.data.costs.totalTransferCosts = this.rounding(
                    // this.transferTotalWithDiscount
                    this.claim.data.costs.partTransferCosts
                    + (this.claim.data.costs.repairCostsEUR * this.claim.data.currency.internal.currencyRate)
                    + (this.claim.data.costs.otherCostsEUR * this.claim.data.currency.internal.currencyRate)
                );
                this.claim.data.costs.partTransferCostsEUR = this.rounding(this.claim.data.costs.partTransferCosts / this.claim.data.currency.internal.currencyRate);
                this.claim.data.costs.totalTransferCostsEUR = this.rounding(this.claim.data.costs.totalTransferCosts / this.claim.data.currency.internal.currencyRate);
            } else {
                this.claim.data.costs.partTransferCostsEUR = this.rounding(this.claim.data.costs.partTransferCosts / this.claim.data.currency.currencyRate);
                // this.claim.data.costs.totalTransferCosts = this.rounding(this.transferTotalWithDiscount + this.claim.data.costs.repairCosts + this.claim.data.costs.otherCosts);
                this.claim.data.costs.totalTransferCosts = this.rounding(this.claim.data.costs.partTransferCosts + this.claim.data.costs.repairCosts + this.claim.data.costs.otherCosts);
                this.claim.data.costs.totalTransferCostsEUR = this.rounding(this.claim.data.costs.totalTransferCosts / this.claim.data.currency.currencyRate);
            }
        }

        // On subsidiary claims, do not calculate repair and other costs to total costs
        if (this.isSubsidiary()) {
            this.claim.data.costs.totalCostsEUR = 0;
        } else {
            this.claim.data.costs.totalCostsEUR = this.rounding(this.claim.data.costs.totalCosts / this.claim.data.currency.currencyRate);
        }
    }
    /**
     * Rounding costs to 2 digits
     * Helper method
     */
    rounding(value) {
        return Math.round(value * 100) / 100;
    }


    // -----------------
    // Comments
    // -----------------

    /**
     * Add comment
     */
    addComment() {
        this.comment = this.comment.trim();
        if (this.comment !== '') {
            if (!this.claim.data.comments) {
                this.claim.data.comments = [];
            }

            const newComment = {
                comment: this.comment,
                timestamp: new Date().toISOString(),
                user: {
                    id: this.authService.userProfile.id,
                    name: this.authService.userProfile.name
                }
            };
            this.claim.data.comments.push(newComment);
            this.comment = '';
        }
    }
    /**
     * Remove comment by index
     */
    removeComment(index) {
        this.claim.data.comments.splice(index, 1);
    }

    /**
     * Add internal comment
     */
    addInternalComment() {
        this.internalComment = this.internalComment.trim();
        if (this.internalComment !== '') {
            if (!this.claim.comments) {
                this.claim.comments = [];
            }

            const newComment = {
                comment: this.internalComment,
                timestamp: new Date().toISOString(),
                user: {
                    id: this.authService.userProfile.id,
                    name: this.authService.userProfile.name
                }
            };
            this.claim.comments.push(newComment);
            this.internalComment = '';
        }
    }
    /**
     * Remove internal comment by index
     */
    removeInternalComment(index) {
        this.claim.comments.splice(index, 1);
    }


    // -----------------
    // Approvals
    // -----------------

    /**
     * Update claim approval process
     * Based on product information, approval process can change
     *
     * KOY = Default approval process
     *      - Dealer -> Subsidiary -> KOY
     * WUXI = Wuxi manufactured products (FastSteel)
     *      - Dealer -> Subsidiary -> WUXI
     * SUBSIDIARY = K2 products
     *      - Dealer -> Subsidiary
     */
    updateClaimApprovalProcess() {
        console.log('updateClaimApprovalProcess()');
        let approvalProcess = 'KOY';
        if (!this.claim.data.products || this.claim.data.products.length === 0) {
            return;
        }
        // Use first (and only) product
        const product = this.claim.data.products[0];
        console.log('updateClaimApprovalProcess()', product);
        if (product && product.productId) {
            const wuxiManufacturedProduct = _.find(WUXI_MANUFACTURED_PRODUCTS, { productId: product.productId});
            if (wuxiManufacturedProduct) {
                approvalProcess = 'WUXI';
            }
            // K2?
            // K2 distributor = approvalProcess = 'WUXI'
            // K2 dealer = approvalProcess = 'SUBSIDIARY'
        }
        _.set(this.claim, 'state.approvalProcess', approvalProcess);
    }

    /**
     * Add approvals
     * Calls save(). Backend handles permission checks and claim status updating
     */
    addApprovals(type) {
        // Check partner type (Dealer, domestic dealer or distributor)
        if (type === 'partner') {
            if (this.claim.company && this.claim.company === '100' && this.claim.partnerId && !this.claim.partnerId.startsWith('FI')) {
                type = 'distributor';
            } else {
                type = 'dealer';
            }
        }
        this.save('add', type);
    }
    /**
     * Remove approvals
     */
    removeApprovals(type) {
        // Check partner type (Dealer, domestic dealer or distributor)
        if (type === 'partner') {
            if (this.claim.company && this.claim.company === '100' && this.claim.partnerId && !this.claim.partnerId.startsWith('FI')) {
                type = 'distributor';
            } else {
                type = 'dealer';
            }
        }
        this.save('remove', type);
    }
    /**
     * Edit approval (admin)
     */
    editApprovals(type) {
        this.save('add', type);
    }

    /**
     * Open confirm dialog to accept additional repair cost or other costs
     * And check that prices are fullfilled at this point
     */
    openApprovalsConfirmDialog(type) {
        // Price validation check
        const validPrices = this.validatePrices();
        // if ((type === 'subsidiary' || type === 'accepted') && !this.validatePrices()) {
        //     this.showError({ message: 'Spare part prices are missing.\nPlease check SPARE PARTS sections.' });
        //     return;
        // }

        // Additional repair cost and other costs check
        if ((type === 'subsidiary' || type === 'accepted')
            && (this.claim.data.others.length > 0 || _.find(this.claim.data.repairs, { partNumber: this.constants.ADDEDREPAIR }) || !validPrices)) {
            const additionalRepairCost: any = _.find(this.claim.data.repairs, { partNumber: this.constants.ADDEDREPAIR });
            const dialogConfig: MatDialogConfig = {
                data: {
                    title: 'confirm_dialog.costs_confirmation',
                    content: 'confirm_dialog.claim_contains_additional_cost',
                    contentRows: [],
                    buttons: {
                        cancel: true,
                        ok: true
                    }
                }
            };
            // Notice for missing prices
            if (!validPrices) {
                dialogConfig.data.content = 'confirm_dialog.claim_contains_missing_prices';
                dialogConfig.data.contentRows.push('<hr><small><b>Missing prices</b></small>');
                for (const part of this.claim.data.parts) {
                    if (part.priceTotal === 0) {
                        dialogConfig.data.contentRows.push(`${part.partNumber} ${part.partName} - ${part.priceTotal} ${this.claim.data.currency.code}`);
                    }
                }
                // Show ok button for admin only
                if (this.authUserRole !== 'sys_admin') {
                    dialogConfig.data.contentRows.push('<hr><small><b>(Only administrator can accept this claim.)</b></small>');
                    dialogConfig.data.buttons.ok = false;
                }
            }

            if (additionalRepairCost) {
                dialogConfig.data.contentRows.push('<hr><small><b>Repair costs</b></small>');
                dialogConfig.data.contentRows.push(`${additionalRepairCost.partName} - ${additionalRepairCost.repairCost} ${this.claim.data.currency.code}`);
            }
            if (this.claim.data.others.length > 0) {
                dialogConfig.data.contentRows.push('<hr><small><b>Other costs</b></small>');
                for (const other of this.claim.data.others) {
                    dialogConfig.data.contentRows.push(`${other.description} - ${other.otherCost} ${this.claim.data.currency.code}`);
                }
            }

            const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
                if (result === 'ok') {
                    this.addApprovals(type);
                }
            });
        } else {
            this.addApprovals(type);
        }
    }


    // -----------------
    // Attachments
    // -----------------

    /**
     * Selected file event.
     * Uses ng2FileSelect library, this is triggered after user is selected file from the computer "Browse" dialog.
     */
    async selectedFile(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            this.attachment = {
                file: null,
                fileExtension: null,
                preview: false,
                content: null
            };

            if (file.size > 5242880) {
                this.showError({ message: 'Failed to lead attachment', type: 'File size too big', reason: 'File size is too big! Max: 5Mb' });
            } else if (file.type && _.find(SUPPORTED_ATTACHMENTS, { extension: fileExtension, type: file.type })) {
                this.attachment.fileExtension = fileExtension;
                this.attachment.file = file;
                const reader = new FileReader();
                reader.onload = async (ev: any) => {
                    this.attachment.content = ev.target.result;
                    // preview only images (MIME type starts with image/)
                    this.attachment.preview = file.type.startsWith('image/');

                    // Safety test adding exception. Automatically save the attachment and save the claim
                    if (this.attachment.file && this.attachment.content && !this.canSave && this.canAddSafety) {
                        await this.saveAttachment();
                        await this.save();
                    }
                };
                reader.readAsDataURL(file);
            } else {
                this.showError({ message: 'Failed to load attachment', type: 'Unsuported file type', reason: file.type });
            }
        }
    }

    /**
     * Save attachment
     * Attachment is uploaded to userdata folder
     */
    async saveAttachment() {
        // Claim must have id (must be saved once to get id from the sequencer)
        if (this.attachment && this.attachment.file && this.claim.id) {
            // Get attachment number
            this.claim.data.attachments = this.claim.data.attachments || [];
            const attachmentIndex = this.claim.data.attachments.length;

            const response = await this.claimsService.saveAttachment(this.claim.company, this.claim.partnerId, this.claim.id, attachmentIndex, this.attachment.content).toPromise() as string;

            const attachmentDetails = {
                index: attachmentIndex,
                filename: response.split('/').pop(),
                fileExtension: this.attachment.fileExtension,
                path: response,
                contentType: this.attachment.file.type,
                size: this.attachment.file.size,
                lastModified: this.attachment.file.lastModifiedDate.toISOString(),
                uploaded: new Date().toISOString()
            };
            this.claim.data.attachments[attachmentIndex] = attachmentDetails;

            // Clear file selection
            this.attachment = {
                file: null,
                fileExtension: null,
                preview: false,
                content: null
            };

            return response;
        }
    }

    /**
     * Open attachment
     * Requests temporary url from backend to open selected attachment file
     */
    async openAttachment(attachmentIndex) {
        const newTab = window.open();
        const response = await this.claimsService.getAttachment(this.claim.company, this.claim.partnerId, this.claim.id, attachmentIndex).toPromise() as string;
        newTab.location.href = response;
    }

    /**
     * Delete attachment by index
     */
    deleteAttachmentRow(index) {
        this.claim.data.attachments.splice(index, 1);
    }


    // -----------------
    // Dialogs
    // -----------------

    /**
     * Open invoice dialog
     * Store reference code to invoice.reference
     * and if internalInvoice use invoice.internalReference
     * and if logistics use invoice.salesOrderNumber
     */
    openInvoiceDialog(type = 'invoiced') {
        const data = {
            type: type,
            invoiceReference: '',
            shippingComment: ''
        };
        const dialogConfig: MatDialogConfig = {
            data: data
        };

        const dialogRef = this.dialog.open(InvoiceDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                if (!this.claim.data.invoice) {
                    this.claim.data.invoice = {
                        reference: null,
                        internalReference: null,
                        salesOrderNumber: null
                    };
                }
                if (type === 'logistics') {
                    this.claim.data.invoice.salesOrderNumber = data.invoiceReference;
                    // Shipping comment
                    const shippingComment = data.shippingComment !== '' ? data.shippingComment : null;
                    if (shippingComment) {
                        _.set(this.claim, 'data.shipping.comment', shippingComment);
                    }
                    this.addApprovals('logistics');
                } else if (type === 'invoicedInternal') {
                    this.claim.data.invoice.internalReference = data.invoiceReference;
                    this.addApprovals('invoicedInternal');
                } else {
                    this.claim.data.invoice.reference = data.invoiceReference;
                    this.addApprovals('invoiced');
                }
            }
        });
    }

    /**
     * Open delete dialog
     */
    openDeleteDialog() {
        const dialogConfig: MatDialogConfig = {
            data: {
                title: 'confirm_dialog.delete_claim',
                content: 'confirm_dialog.are_you_sure_you_want_to_delete_claim'
            }
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                this.delete();
            }
        });
    }

    /**
     * Toggle actions (buttons)
     * Shown only in extra small layout.
     */
    toggleActions() {
        this.showActions = !this.showActions;
    }

    /**
     * Toggle admin tools
     */
    toggleAdmin() {
        if (this.authUserRole === 'sys_admin') {
            this.showAdminTools = !this.showAdminTools;
        }
    }

    /**
     * Get new claim template
     */
    getNewClaimTemplate() {
        const dateNow = new Date();
        const claim: Claim = {
            id: null,
            company: this.company,
            partnerId: this.partnerId,
            partnerName: '',
            data: {
                repairDate: new Date().toISOString(),
                customer: {
                    companyName: '',
                    contactPersonName: ''
                },
                partner: {
                    name: '',
                    groupCode: '',
                    groupDesc: ''
                },
                language: '',
                claimType: 'SERIAL',
                faultyDescription: {
                    code: '11000',
                    version: '1',
                    category: 'functional-failure',
                    type: null,
                    section: null,
                    reason: null
                },
                products: [{}],
                parts: [
                    // partNumber
                    // partName
                ],
                repairs: [
                    // partNumber
                    // partName
                    // minutes
                    // pricePerHour
                    // repairCost
                ],
                others: [
                    // description
                    // otherCost
                ],
                repair: {
                    labourPrice: 0,
                    // labourCurrency
                    minutes: 0,
                    hours: 0,
                    time: ''
                },
                costs: {
                    partCosts: 0,
                    partCostsEUR: 0,
                    partTransferCosts: 0,
                    partTransferCostsEUR: 0,
                    partTransferCostsWithoutDiscount: 0,
                    // workCosts: 0,
                    // workCostsEUR: 0,
                    repairCosts: 0,
                    repairCostsEUR: 0,
                    otherCosts: 0,
                    otherCostsEUR: 0,
                    totalTransferCosts: 0,
                    totalTransferCostsEUR: 0,
                    totalCosts: 0,
                    totalCostsEUR: 0
                },
                currency: {
                    code: 'EUR',
                    currencyRate: 1.000,
                    effectiveDate: null
                },
                safetyTest: false,
                attachments: [],
                comments: [],
                shipping: {
                    comment: ''
                }
            },
            state: {
                approvalProcess: 'KOY',
                closed: false,
                currentState: 'DRAFT',
                internalState: '',
                logisticsState: '',
                invoiceState: '',
                internalInvoiceState: ''
            },
            schema: {
                type: 'warranty-claim',
                version: '1.0'
            },
            approvals: {},
            version: {}
        };
        return claim;
    }

    // -----------------
    // Notifications
    // -----------------

    loadWarningTranslations() {
        this.translations = {
            changed: this.translateService.instant('warning.changed') || 'Changed',
            expired: this.translateService.instant('warning.expired') || 'Expired',
            notRegistered: this.translateService.instant('warning.not_registered') || 'Not registered'
        };
    }

    /**
     * Show error snackbar notification
     */
    showError(error) {
        const message = _.get(error, 'message');
        const type = _.get(error, 'type') || _.get(error, 'error.error.caused_by.caused_by.type') || _.get(error, 'error.error.caused_by.type') || '';
        const reason = _.get(error, 'reason') || _.get(error, 'error.error.caused_by.caused_by.reason') || _.get(error, 'error.error.caused_by.reason') || '';

        this.snackBar.open(message + '\n\n' + type + '\n' + reason, 'OK', {
            duration: 5000,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }

    /**
     * Show snackbar notification
     */
    notify(translationKey, id = '', duration = 3000) {
        this.translateService.get(translationKey).subscribe(translation => {
            const message = _.join([translation, id], '\n');
            this.snackBar.open(message, 'OK', {
                duration: duration,
                panelClass: ['war-snackbar']
            });
        });
    }

    print() {
        window.print();
    }

}
